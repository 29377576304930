.page-title {
  font-size: 30px !important;
  font-weight: bold !important;
  color: #475160 !important;
}
.wrap-txt-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.txt-header {
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  /* identical to box height, or 104% */

  letter-spacing: -0.02em;

  /* neutral/900 */

  color: #475160;
}
